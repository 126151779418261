<template>
  <div class="page-influencer-comparison extra-large-page">
    <compare-table
      v-if="shouldShowResults"
      :platform="selectedPlatform"
      :profiles="profileItems"
      :key="uniqueKey"
    ></compare-table>

    <v-card
      v-else
      max-width="600"
      class="mx-auto accent mt-sm-8 mt-md-12 mt-lg-16"
      flat
    >
      <v-card-title>
        Compare Influencers
      </v-card-title>

      <v-card-subtitle>
        Add upto 3 profiles at once
      </v-card-subtitle>

      <v-card-text>
        <v-chip-group
          v-if="selectedItems.length"
          class="mb-3"
        >
          <v-chip
            v-for="item in selectedItems"
            :key="item.symbol"
            @click:close="removeSelectedItem(item.symbol)"
            close
          >
            <v-avatar left>
              <v-img :src="item.data.picture" />
            </v-avatar>

            {{ item.data.nameToShow }}
          </v-chip>
        </v-chip-group>

        <div class="d-flex mt-3 mt-sm-0">
          <!-- show the input for searching terms -->
          <profile-selector
            v-model="influencerSearch"
            @change="handleProfileChange"
            :platform="selectedPlatform"
            :hide-no-data="true"
            :label="shouldInputBeDisabled ? 'Maximum limit reached' : 'Search Profile'"
            :disabled="shouldInputBeDisabled"
            class="rounded-r-0"
            type="search"
            flat
            solo
          />

          <!-- the select options for different platforms -->
          <platform-selector
            :value="selectedPlatform"
            @input="handlePlatformChange"
            class="rounded-l-0"
          />
        </div>
      </v-card-text>

      <v-card-actions class="px-4">
        <v-spacer></v-spacer>

        <v-btn
          :class="{ 'shadow--primary': !shouldCTABeDisabled }"
          :disabled="shouldCTABeDisabled"
          :loading="isLoading"
          color="primary"
          type="submit"
          large
          depressed
          @click="handleCompareClick"
        >
          Compare
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// Selector Blocks
const ProfileSelector = () => import(/* webpackChunkName: "profile-selector" */ "@/blocks/common/selectors/ProfileSelector.vue")
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/blocks/common/selectors/PlatformSelector.vue")

// Profile Component
const CompareTable = () => import(/* webpackChunkName: "compare-table" */ "@/components/influencer-tie-breaker/CompareTable.vue")

// Export the SFC
export default {
  // Name of the component
  name: "InfluencerTieBreaker",

  // Register the children components
  components: {
    ProfileSelector,
    PlatformSelector,

    CompareTable
  },

  // The local data variables
  data: () => ({
    // when the page is mounted, show a dialog to select the items
    shouldShowResults: false,
    uniqueKey: Symbol(),

    loadingItems: [],

    // initiate with Instagram
    selectedPlatform: "instagram",
    influencerSearch: "",

    minItems: 2,
    maxItems: 3,
    selectedItems: [],
    profileItems: []
  }),

  // Local computable readonly variables
  computed: {
    /**
     * Whether or not the input should be disabled
     *
     * @returns {Boolean}
     */
    shouldInputBeDisabled() {
      return this.selectedItems.length >= this.maxItems
    },

    /**
     * Whether or not a request is being made
     *
     * @returns {Boolean}
     */
    isLoading() {
      return this.loadingItems.length > 0
    },

    /**
     * Should the form submit button be disabled
     *
     * @returns {Boolean}
     */
    shouldCTABeDisabled() {
      return this.selectedItems.length < this.minItems || this.isLoading
    }
  },

  // Define local method functions
  methods: {
    /**
     * When a profile has been selected from the dropdown, fetch it's report
     *
     * @param {Object} selectedUser
     * @returns {Promise<void>}
     */
    async handleProfileChange(selectedUser) {
      // if the value is null or false
      if (!selectedUser) return false
      // if the value is not an object
      if (typeof selectedUser !== 'object') return false

      // put this in the searchable array
      const symbol = Symbol()

      // reset the unique value to
      this.uniqueKey = symbol

      this.selectedItems.push({
        symbol,
        data: selectedUser
      })

      // let the input be ready for other values
      this.influencerSearch = ""

      // push the item in loadingItems
      this.loadingItems.push(symbol)

      // fetch the user data for this profile
      await this.$store.dispatch("influencerInsight/fetchProfile", {
        platform: this.selectedPlatform,
        username: selectedUser.idToQuery,
        accountId: selectedUser.idToQuery,
        showError: true
      })

      // remove the loading item from it
      const loadingIndex = this.loadingItems.findIndex((search) => search === symbol)

      if (loadingIndex !== -1) {
        this.loadingItems.splice(loadingIndex, 1)
      }

      const getterData = this.$store.getters["influencerInsight/findProfile"]({ platform: this.selectedPlatform, accountId: selectedUser.idToQuery })
      const index = this.selectedItems.findIndex((search) => search.symbol === symbol)

      // now check if the selected item has not been deleted
      if (index !== -1) {
        if (getterData && getterData.isError === false) {
          this.profileItems.push({
            symbol,
            data: getterData
          })
        }
        // if there was some issue, hide the selected user from the list
        else {
          this.selectedItems.splice(index, 1)
        }
      }
    },

    /**
     * When the platform changes, reset the selected profiles
     *
     * @param {String} value
     * @returns {void}
     */
    handlePlatformChange(value) {
      this.selectedPlatform = value

      this.resetState()
    },

    /**
     * Reset the values for selected profiles and other state variables
     *
     * @returns {void}
     */
    resetState() {
      this.shouldShowResults = false
      this.influencerSearch = ""
      this.selectedItems = []
      this.profileItems = []
    },

    /**
     * Handle the submit button click
     *
     * @returns {void}
     */
    handleCompareClick() {
      // if the data is being loaded
      if (this.isLoading) {
        this.$store.dispatch("toasts/add", {
          text: "Please wait while the data is being loaded"
        })

        return false
      }

      // if it's not loading
      // check if the there are at least 2 items
      if (this.selectedItems.length < this.minItems) {
        this.$store.dispatch("toasts/add", {
          text: "Please add at least 2 profiles for the comparison"
        })

        return false
      }

      // otherwise
      this.shouldShowResults = true
    },

    /**
     * If called, removes the report from selected profiles array
     *
     * @param {Symbol} symbol
     * @returns {void}
     */
    removeSelectedItem(symbol) {
      const itemIndex = this.selectedItems.findIndex((search) => search.symbol === symbol)
      const profileIndex = this.profileItems.findIndex((search) => search.symbol === symbol)

      if (itemIndex !== -1) {
        this.selectedItems.splice(itemIndex, 1)
      }

      if (profileIndex !== -1) {
        this.profileItems.splice(profileIndex, 1)
      }
    }
  }
}
</script>

<style lang="stylus">
// to contain the entire box structure in large viewport
.page-influencer-comparison
  overflow-x auto

  .options-container
    max-width 1120px
    margin 0 auto

  .results-container
    max-width 1320px
    margin 0 auto
</style>
